import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography } from '@mui/material';

const teamMembers = [
  { id: 1, name: 'Mohammed AE', role: '', phone: '(865)220-6322' },
  { id: 2, name: 'Amina Akhmatova', role: '', phone: '(865)666-7911' },
  { id: 3, name: 'Lera Rubina', role: '', phone: '(865)444-6798' },
  { id: 4, name: 'Mike Col', role: '', phone: '(865)666-4404' },
  { id: 5, name: 'Thomas Byer', role: '', phone: '(865)322-9203' },
];

const Team = () => {
  return (
    <TableContainer component={Paper} sx={{ margin: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Our Team
      </Typography>
      <Table>
        <TableBody>
          {teamMembers.map((member) => (
            <TableRow key={member.id}>
              <TableCell>{member.id}</TableCell>
              <TableCell>{member.role}</TableCell>
              <TableCell>{member.name}</TableCell>
              <TableCell>{member.phone}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Team;
