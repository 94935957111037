import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Divider,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const stateOptions = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  // Add more states as needed
];

function DriverHiring() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    experience: "",
    comments: "",
    ssn: "",
    birthDate: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    extraAddresses: "",
    livedAtCurrentAddressLessThan3Years: false,
    companyName: "",
    companyAddress: "",
    companyPhone: "",
    workedAtCurrentCompany10Years: false,
    previousEmployment: "",
    applyingFor: "",
    vehicleTypes: {
      dryVan: false,
      reefer: false,
      flatbed: false,
    },
    cdlExpiration: "",
    cdlFile: null,
    medicalCardExpiration: "",
    medicalCardFile: null,
  });

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        vehicleTypes: {
          ...prevData.vehicleTypes,
          [name]: checked,
        },
      }));
    } else if (type === "file") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted", formData);
    // Add API call or success message here
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Driver Hiring Application
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please fill out the form below to apply for a driving position.
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          {/* Applying For Section */}
          <Typography variant="h5" gutterBottom>
            Applying For
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Select
                required
                fullWidth
                name="applyingFor"
                value={formData.applyingFor}
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select Position
                </MenuItem>
                <MenuItem value="Company Driver">Company Driver</MenuItem>
                <MenuItem value="Owner Operator">Owner Operator</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Vehicle Types:</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    name="dryVan"
                    checked={formData.vehicleTypes.dryVan}
                    onChange={handleChange}
                  />
                }
                label="Dry Van"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="reefer"
                    checked={formData.vehicleTypes.reefer}
                    onChange={handleChange}
                  />
                }
                label="Reefer"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="flatbed"
                    checked={formData.vehicleTypes.flatbed}
                    onChange={handleChange}
                  />
                }
                label="Flatbed"
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: 4 }} />

          {/* Personal Info Section */}
          <Typography variant="h5" gutterBottom>
            Personal Info
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Email Address"
                name="email"
                value={formData.email}
                onChange={handleChange}
                type="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Phone Number"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                type="tel"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Years of Experience"
                name="experience"
                value={formData.experience}
                onChange={handleChange}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="SSN / SIN"
                name="ssn"
                value={formData.ssn}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Birth Date"
                name="birthDate"
                value={formData.birthDate}
                onChange={handleChange}
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: 4 }} />

          {/* Address Section */}
          <Typography variant="h5" gutterBottom>
            Address
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Street Address"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="City"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                required
                fullWidth
                value={formData.state}
                name="state"
                onChange={handleChange}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Select State
                </MenuItem>
                {stateOptions.map((state, index) => (
                  <MenuItem key={index} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Postal Code"
                name="postalCode"
                value={formData.postalCode}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="livedAtCurrentAddressLessThan3Years"
                    checked={formData.livedAtCurrentAddressLessThan3Years}
                    onChange={handleChange}
                  />
                }
                label="I have lived at my current address for less than 3 years"
              />
            </Grid>
          </Grid>

          {formData.livedAtCurrentAddressLessThan3Years && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" gutterBottom>
                Additional Addresses
              </Typography>
              <TextField
                fullWidth
                label="Provide additional address details"
                name="extraAddresses"
                value={formData.extraAddresses}
                onChange={handleChange}
                multiline
                rows={4}
              />
            </Box>
          )}

          <Divider sx={{ my: 4 }} />

          {/* Employment History Section */}
          <Typography variant="h5" gutterBottom>
            Employment History
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Company Name"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Company Address"
                name="companyAddress"
                value={formData.companyAddress}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Company Phone"
                name="companyPhone"
                value={formData.companyPhone}
                onChange={handleChange}
                type="tel"
              />
            </Grid>
          </Grid>

          <Divider sx={{ my: 4 }} />

          {/* Documents Section */}
          <Typography variant="h5" gutterBottom>
            Documents
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="CDL Expiration Date"
                name="cdlExpiration"
                value={formData.cdlExpiration}
                onChange={handleChange}
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                component="label"
                fullWidth
                sx={{ height: "56px" }}
              >
                Upload CDL
                <input
                  type="file"
                  name="cdlFile"
                  hidden
                  onChange={handleChange}
                />
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Medical Card Expiration Date"
                name="medicalCardExpiration"
                value={formData.medicalCardExpiration}
                onChange={handleChange}
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                component="label"
                fullWidth
                sx={{ height: "56px" }}
              >
                Upload Medical Card
                <input
                  type="file"
                  name="medicalCardFile"
                  hidden
                  onChange={handleChange}
                />
              </Button>
            </Grid>
          </Grid>

          <Divider sx={{ my: 4 }} />

          {/* Additional Comments Section */}
          <Typography variant="h5" gutterBottom>
            Additional Comments
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Additional Comments"
                name="comments"
                value={formData.comments}
                onChange={handleChange}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ mt: 4 }}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit Application
            </Button>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}

export default DriverHiring;
