import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, Box, Divider, Button, CircularProgress } from '@mui/material';

const LoadDetails = ({ load }) => {
  const [messages, setMessages] = useState([]); // Initialize messages as an empty array
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const fetchMessages = async () => {
    setLoading(true);
    try {
      const response = await fetch(`/api/get_telegram_message?loads_id=${load.id}`);
      if (response.ok) {
        const data = await response.json();
        setMessages(Array.isArray(data) ? data : []); // Ensure messages is always an array
      } else {
        console.error('Failed to fetch messages:', response.statusText);
        setMessages([]);
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
      setMessages([]);
    } finally {
      setLoading(false);
    }
  };

  const handleExpand = () => {
    if (!expanded) {
      fetchMessages(); // Fetch messages when expanding
    }
    setExpanded(!expanded);
  };

  // Document type mapping
  const getDocumentType = (type) => {
    switch (type) {
      case 0:
        return 'Rate Confirmation';
      case 1:
        return 'Bill of Landing';
      case 2:
        return 'Proof of Delivery';
      default:
        return 'Unknown';
    }
  };

  // Check type mapping
  const getCheckType = (type) => {
    switch (type) {
      case 0:
        return 'Service Fee';
      case 1:
        return 'Lumper Fee';
      case 2:
        return 'Gate Fee';
      case 3:
        return 'Permit Fee';
      case 4:
        return 'Pilot Fee';
      default:
        return 'Unknown';
    }
  };

  return (
    <Box p={4}>
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Load Details
          </Typography>

          {/* General Information */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">General Information</Typography>
              <Divider />
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Load ID:</strong> {load.load_id?.String || 'N/A'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Dead Head:</strong> {load.dead_head?.Float64 ? `${load.dead_head.Float64} miles` : 'N/A'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Distance:</strong> {load.distance?.Float64 ? `${load.distance.Float64} miles` : 'N/A'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Gross:</strong> {load.gross?.Float64 ? `$${load.gross.Float64}` : 'N/A'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Tarp:</strong> {load.tarp?.Bool ? 'Yes' : 'No'}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1">
                <strong>Canceled:</strong> {load.canceled?.Bool ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>

          {/* Location Information */}
          <Box mt={4}>
            <Typography variant="h6">Locations</Typography>
            <Divider />
            {load.locations && load.locations.length > 0 ? (
              load.locations
                .sort((a, b) => a.location_order - b.location_order)
                .map((location, index) => (
                  <Box key={index} mt={2}>
                    <Typography variant="body1">
                      <strong>Location {index + 1} ({location.type === 0 ? 'Pickup' : 'Delivery'}):</strong>{' '}
                      {location.location?.address?.String || 'N/A'}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Time In:</strong> {location.time_in?.String || 'N/A'}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Time Out:</strong> {location.time_out?.String || 'N/A'}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Timestamp In:</strong> {new Date(location.time_stamp_in).toLocaleString()}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Timestamp Out:</strong> {new Date(location.time_stamp_out).toLocaleString()}
                    </Typography>
                  </Box>
                ))
            ) : (
              <Typography variant="body2">No locations available</Typography>
            )}
          </Box>

          {/* Documents Section */}
          <Box mt={4}>
            <Typography variant="h6">Documents</Typography>
            <Divider />
            {load.documents && load.documents.length > 0 ? (
              load.documents.map((document, index) => (
                <Box key={index} mt={2}>
                  <Typography variant="body1">
                    <strong>Type:</strong> {getDocumentType(document.type)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Info:</strong> {document.info?.String || 'N/A'}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body2">No documents available</Typography>
            )}
          </Box>

          {/* Checks Section */}
          <Box mt={4}>
            <Typography variant="h6">Checks</Typography>
            <Divider />
            {load.checks && load.checks.length > 0 ? (
              load.checks.map((check, index) => (
                <Box key={index} mt={2}>
                  <Typography variant="body1">
                    <strong>Type:</strong> {getCheckType(check.type)}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Info:</strong> {check.info?.String || 'N/A'}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Cost:</strong> {check.cost?.Float64 ? `$${check.cost.Float64}` : 'N/A'}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body2">No checks available</Typography>
            )}
          </Box>

          {/* Driver Section */}
          <Box mt={4}>
            <Typography variant="h6">Driver</Typography>
            <Divider />
            {load.driver ? (
              <Box mt={2}>
                <Typography variant="body1">
                  <strong>Name:</strong> {`${load.driver.first_name} ${load.driver.last_name}`}
                </Typography>
                <Typography variant="body1">
                  <strong>Telegram:</strong> {load.driver.telegram || 'N/A'}
                </Typography>
                <Typography variant="body1">
                  <strong>Vacation:</strong> {load.driver.vacation || 'N/A'}
                </Typography>
              </Box>
            ) : (
              <Typography variant="body2">No driver assigned</Typography>
            )}
          </Box>

          {/* Truck Section */}
          <Box mt={4}>
            <Typography variant="h6">Truck</Typography>
            <Divider />
            {load.truck ? (
              <Box mt={2}>
                <Typography variant="body1">
                  <strong>Truck ID:</strong> {load.truck.truck_id || 'N/A'}
                </Typography>
                <Typography variant="body1">
                  <strong>Rented:</strong> {load.truck.rented ? 'Yes' : 'No'}
                </Typography>
              </Box>
            ) : (
              <Typography variant="body2">No truck assigned</Typography>
            )}
          </Box>

          {/* Trailer Section */}
          <Box mt={4}>
            <Typography variant="h6">Trailer</Typography>
            <Divider />
            {load.trailer ? (
              <Box mt={2}>
                <Typography variant="body1">
                  <strong>Trailer ID:</strong> {load.trailer.trailer_id || 'N/A'}
                </Typography>
                <Typography variant="body1">
                  <strong>Rented:</strong> {load.trailer.rented ? 'Yes' : 'No'}
                </Typography>
              </Box>
            ) : (
              <Typography variant="body2">No trailer assigned</Typography>
            )}
          </Box>

          {/* Additional Information */}
          <Box mt={4}>
            <Typography variant="h6">Additional Information</Typography>
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Tarp:</strong> {load.tarp?.Bool ? 'Yes' : 'No'}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Oversize:</strong> {load.oversize?.Bool ? 'Yes' : 'No'}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* Checked Information */}
          <Box mt={4}>
            <Typography variant="h6">Checked Information</Typography>
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Accountant Checked:</strong> {load.accountant_checked?.Bool ? 'Yes' : 'No'}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography variant="body1">
                  <strong>Dispatch Checked:</strong> {load.dispatch_checked?.Bool ? 'Yes' : 'No'}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* Telegram Messages Section */}
          <Box mt={4}>
            <Typography variant="h6">Telegram Messages</Typography>
            <Divider />
            <Button variant="contained" onClick={handleExpand} sx={{ mt: 2 }}>
              {expanded ? 'Hide Messages' : 'Show Messages'}
            </Button>
            {expanded && (
              <Box mt={2}>
                {loading ? (
                  <CircularProgress />
                ) : messages.length > 0 ? (
                  messages.map((message, index) => (
                    <Box key={index} mt={2}>
                      <Typography variant="body1">
                        <strong>Message:</strong> {message.message || 'N/A'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Chat:</strong> {message.chat || 'N/A'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>First Name:</strong> {message.first_name || 'N/A'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Last Name:</strong> {message.last_name || 'N/A'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Username:</strong> {message.user_name || 'N/A'}
                      </Typography>
                      <Divider sx={{ my: 2 }} />
                    </Box>
                  ))
                ) : (
                  <Typography variant="body2">No messages available</Typography>
                )}
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LoadDetails;
