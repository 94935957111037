import React from 'react';
import { Typography, Box, Paper } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Box sx={{ margin: 3 }}>
      <Paper sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body2" gutterBottom>
          At PrimeTurbo, we value your privacy and are committed to protecting your personal information. This Privacy Policy is designed to help you understand how we collect, use, disclose, and safeguard your personal information when you visit our website or use our products and services.
                         By accessing our website or using our products and services, you consent to the practices described in this Privacy Policy. Please read this policy carefully to understand your rights and responsibilities regarding your personal information.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Information We Collect
        </Typography>
        <Typography variant="body2" gutterBottom>
        We may collect the following types of information:<br />
          - Personal Information: This may include your name, email address, phone number, postal address, and any other information you provide when  lling out forms on our website or when contacting us.<br />
          - Usage Information: We may collect information about your interactions with our website and services, such as your IP address, browser type, operating system, and browsing behavior.<br />
          - Cookies: We use cookies and similar tracking technologies to collect information about your browsing preferences, such as the pages you visit, the links you click, and other actions you take on our website.
        </Typography>
        <Typography variant="h6" gutterBottom>
          How We Use Your Information
        </Typography>
        <Typography variant="body2" gutterBottom>
        Cookies: We use cookies and similar tracking technologies to collect information about your browsing preferences, such as the pages you visit, the links you click, and other actions you take on our website.<br />
          - To provide and maintain our products and services.<br />
          - To communicate with you and respond to your inquiries.<br />
          - To improve and personalize your experience on our website.<br />
          - To send you marketing communications, promotions, and updates if you have provided your consent.<br />
          - To monitor and analyze website usage and trends.<br />
          - To comply with legal obligations.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Security
        </Typography>
        <Typography variant="body2" gutterBottom>
          We implement appropriate security measures to protect your data. However, no method of transmission over the internet is
          100% secure.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Sharing Your Information
        </Typography>
        <Typography variant="body2" gutterBottom>
          - We won’t trade, sell or share your personal information with any third party as SMS consent will not be shared to any third party for emergency purposes.<br />
          - Legal Requirements: We may disclose your information if required by law or to protect our rights, privacy, safety, or property.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Changes to This Policy
        </Typography>
        <Typography variant="body2" gutterBottom>
          We may update this policy from time to time. Please review it periodically.
        </Typography>
        <Typography variant="body2" gutterBottom>
          If you have any questions about this Privacy Policy, please contact us at support@primeturbo.com.
        </Typography>
      </Paper>
    </Box>
  );
};

export default PrivacyPolicy;
